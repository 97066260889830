import * as React from "react";
import {SubjectApiData} from "../../models/subjectApi.model";
import { ChartBarIcon } from "@heroicons/react/outline";
import {Link} from "gatsby";


type DashboardProps = {
    data: SubjectApiData;
};

const DashboardTile: React.FC<DashboardProps> = ({ data }) => {
    return (
        <Link to={`/dashboardview/?id=${data.id}`}>
            <div className={"bg-white rounded-md lg:rounded-lg p-1 lg:p-4 max-w-[900px] mx-auto my-4 border hover:border-blue-800 hover:drop-shadow-md"}>
                <div className={"flex"}>
                    <div className={"flex-grow"}>
                        <h1 className={"text-xl"}>{data.frontEndLabel}</h1>
                    </div>
                    <div className={"mt-2"}><ChartBarIcon width={20} /></div>
                </div>
                {
                    data.frontEndDescription ? (
                        <div className={"text-md p-4 text-gray-600"}>
                            <p>
                                {data.frontEndDescription}
                            </p>
                        </div>
                    ) : null
                }
            </div>
        </Link>
    )
}

export default DashboardTile;
import * as React from "react";
import useGetUserDashboardsData from "./getDashboards";
import {useEffect} from "react";
import DashboardTile from "./DashboardTile";
import Spinner from "../animation/Spinner";

const DashboardsPage = () => {

    const { isLoading, error, execute, data } = useGetUserDashboardsData()

    useEffect(() => {
        execute();
    }, [execute]);

    const getDashboards = () => {
        if (isLoading) {
            return (
                <div className={"flex items-center"}>
                    <div className={"m-auto pt-8 pb-8"}>
                        <Spinner width={100} height={100} />
                    </div>
                </div>
            )
        }

        if (!data || error) {
            return (
                <div>ERROR no dashboards</div>
            )
        }

        return (
            <div>
                {data.dashboards.map((item, index) => (
                    <DashboardTile data={item} key={item.id} />
                ))}
            </div>
        )


    }

    return (
        <div>
            {getDashboards()}
        </div>
    );
};

export const Head = () => (
    <>
        <title>Runic | Dashboards</title>
    </>
)

export default DashboardsPage;
